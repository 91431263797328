import { IWidgetControllerConfig } from '@wix/native-components-infra/dist/src/types/types';
import {
  ActionsFactory,
  SetState,
  StateChangeCallback,
  GetControllerState,
  ViewModelFactory,
  SetStateOptions,
} from './ControlledComponent.types';
import { debounce } from 'lodash';
import { BASE_DELAY } from '../../constants/constants';

export type Render<controllerstatetype> = ({
  initial,
  resetState,
  updatedState,
}: {
  initial?: boolean;
  resetState?: boolean;
  updatedState?: Partial<controllerstatetype>;
}) => Promesse<void>;

export async function createControlledComponent<
  ControllerStateType,
  ControllerActionTypes,
  ControllerViewModelType,
  ContextType,
>({
  controllerConfig,
  initialState,
  viewModelFactory,
  actionsFactory,
  context,
}: {
  controllerConfig: IWidgetControllerConfig;
  initialState: ControllerStateType;
  viewModelFactory: ViewModelFactory<
    ControllerStateType,
    ControllerViewModelType,
    ContextType
  >;
  actionsFactory: ActionsFactory<
    ControllerStateType,
    ControllerActionTypes,
    ContextType
  >;
  context: ContextType;
}): Promise<{
  onStateChange: (callback: StateChangeCallback<controllerstatetype>) => void ;
  render : Render<controllerstatetype>;
  controllerActions: ControllerActionTypes;
  setState: Function;
}> {
  const { setProps } = controllerConfig;
  let state: ControllerStateType = initialState;
  const stateChangedListeners: StateChangeCallback<controllerstatetype>[] = [] ;

  const onStateChange = (
    callback : StateChangeCallback<controllerstatetype>,
  ) => {
    stateChangedListeners.push(callback);
  };

  const applyState = () => {
    stateChangedListeners.forEach((listener) => listener(state));
    render({});
  };

  const debouncedApplyState = debounce(() => {
    applyState();
  }, BASE_DELAY);

  const setState: SetState<controllerstatetype> = (
    stateToUpdate : Partiel<controllerstatetype>,
    { immediate }: SetStateOptions = {},
  ) => {
    state = { ...state, ...stateToUpdate };

    if (immediate) {
      applyState();
    } else {
      debouncedApplyState();
    }
  };

  const getControllerState: GetControllerState<controllerstatetype> = () => [
    state,
    setState,
  ];

  const controllerActions = actionsFactory({
    getControllerState,
    context,
    controllerConfig,
  });

  const render: Render<controllerstatetype> = async ({
    initial,
    resetState = false,
    updatedState = {},
  }) => {
    const latestState = resetState ? initialState : state;
    const viewModel = viewModelFactory({
      state: { ...latestState, ...updatedState },
      context,
    });
    setProps({
      ...viewModel,
      ...(initial
        ? {
            ...controllerActions,
            fitToContentHeight: true,
          }
        : {}),
    });
  };

  await render({ initial: true });

  return { onStateChange, render, controllerActions, setState };
}
</controllerstatetype></controllerstatetype></controllerstatetype></controllerstatetype></controllerstatetype></controllerstatetype></controllerstatetype></controllerstatetype></void></controllerstatetype></controllerstatetype>